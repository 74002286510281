let fcIcpHome = () => {
    let thisScope = {
        'fund': '=fund',
        'individualIdentityDocuments': '=individualIdentityDocuments',
        'individualInvestorProfile': '=individualInvestorProfile',
        'investment': '=investment',
        'investorProfile': '=investorProfile',
        'investorProfileIdentityDocuments': '=investorProfileIdentityDocuments',
        'optInInvestments': '=optInInvestments',
        'payment': '=payment',
        'scrollTo': '=scrollTo',
        'subscriptionAgreement': '=subscriptionAgreement',
        'taxDocs': '=taxDocs',
        'userProfile': '=userProfile',
    };
    let thisController = function(
        $intercom: any,
        $stateParams: any,
        $timeout: any,
        $window: any,
        $http: any,
        $state: any,
        FCChoices: any,
        FundOptInInvestment: any
    ) {
        let vm = this;
        vm.choices = FCChoices;
        vm.sendingEmailConfirmation = false;
        vm.showInvestmentStatusExplanation = false;
        vm.shouldAutoScroll = false;

        vm.COMPLETE = 'complete';
        vm.INCOMPLETE = 'incomplete';
        vm.PENDING = 'pending';

        vm.hellosignState = '';
        vm.onHellosignClick = () => {
            vm.hellosignState = 'generating';
            $http.post('/hellosign-test/').then((resp: any) => {
                let data = resp.data;
                vm.hellosignState = data.url;
                $state.go('icp.testHellosign', {testUrl: data.url});
            });
        };

        $intercom.showLauncher();

        // We have an updated transaction, lets replace the outdated version of it
        // in vm.payment.transactions
        if ($stateParams.transaction) {
            vm.payment.transactions = vm.payment.transactions.filter(
                (transaction: any) => {
                    return transaction.id !== $stateParams.transaction.id;
                }
            );
            vm.payment.transactions.push($stateParams.transaction);
        }

        // If we get here and we have optInInvestments, they should all be in sync.
        // For convenience, take the first one and save it's requestedAmount
        if (vm.optInInvestments.length) {
            vm.optInRequestedAmount = vm.optInInvestments[0].requested_amount;
        }

        vm.askForRefundAccount = () => {
            return (
                !vm.investorProfile.is_ira &&
                vm.investment.is_waitlisted &&
                vm.payment.user_marked_offline_payment_sent !== FCChoices.OfflinePaymentSentChoices.NotNeeded
            );
        };

        let relevantSteps = () => {
            let steps: any = [
                'signSubscriptionAgreement',
            ];

            if (!vm.userProfile.email_verified) {
                steps.push('emailVerification');
            }

            if (vm.investment.individual_identity_verification_relevant) {
                steps.push('individualIdentityVerification');
            }

            if (
                vm.payment.unaccounted_amount > 0 &&
                vm.payment.transaction_status !== 'f'
            ) {
                steps.push('payment');
                if (vm.investorProfile.is_ira && vm.stepComplete('iraAccountStatus')) {
                    steps.push('fundIra');
                }
            }

            if (vm.investorProfile.is_ira) {
                steps.push('iraAccountStatus');
            } else if (vm.investment.irs_form_relevant) {
                steps.push('signTaxDocs');
            }

            if (vm.investorProfile.is_entity) {
                steps.push('entityAccreditation');

                if (vm.investment.investment_identity_verification_relevant) {
                    steps.push('entityIdentityVerification');
                }
            }

            for (let transaction of vm.payment.transactions) {
                steps.push(['transaction', transaction]);
                if (transaction.their_bank_account && !transaction.their_bank_account.verified) {
                    steps.push(['verifyBankAccount', transaction]);
                }
            }

            if (vm.optInInvestments.length > 0) {
                steps.push('fundOptIns');
            }

            if (!vm.investorProfile.is_ira &&
                vm.investment.is_waitlisted &&
                vm.payment.user_marked_offline_payment_sent !== FCChoices.OfflinePaymentSentChoices.NotNeeded) {
                steps.push('selectRefundAccount');
            }

            return steps;
        };

        vm.resendEmailConfirmation = () => {
            vm.sendingEmailConfirmation = true;
            vm.userProfile
                .resendEmailConfirmation()
                .then((response: any) => {
                    vm.sendingEmailConfirmation = false;
                    vm.userProfile.email_confirmation_recently_sent = true;
                });
        };

        vm.showIntercom = () => {
            $intercom.showNewMessage();
        };

        vm.stepComplete = (stepName: any) => {
            return vm.stepStatus[stepName]() === vm.COMPLETE;
        };

        vm.stepIncomplete = (stepName: any) => {
            return vm.stepStatus[stepName]() === vm.INCOMPLETE;
        };

        vm.stepPending = (stepName: any) => {
            return vm.stepStatus[stepName]() === vm.PENDING;
        };

        vm.stepStatus = {
            'emailVerification': () => {
                if (vm.userProfile.email_verified) {
                    return vm.COMPLETE;
                }

                if (vm.userProfile.email_confirmation_recently_sent) {
                    return vm.PENDING;
                }

                return vm.INCOMPLETE;
            },
            'entityAccreditation': () => {
                if (vm.investment.questionnaire_completed) {
                    if (vm.investorProfile.accredited) {
                        return vm.COMPLETE;
                    } else {
                        return vm.PENDING;
                    }
                }
                return vm.INCOMPLETE;
            },
            'entityIdentityVerification': () => {
                if (vm.investorProfile.identity_verified) {
                    return vm.COMPLETE;
                }

                if (vm.investorProfileIdentityDocuments.length) {
                    return vm.PENDING;
                }

                return vm.INCOMPLETE;
            },
            'fundIra': () => {
                if (
                    vm.payment.user_marked_offline_payment_sent !==
                    FCChoices.OfflinePaymentSentChoices.NotSent
                ) {
                    return vm.PENDING;
                } else {
                    return vm.INCOMPLETE;
                }
            },
            'fundOptIns': () => {
                if (vm.optInInvestments.length === 0) {
                    return vm.COMPLETE;
                }

                const completedOptIns = vm.optInInvestments.filter(
                    (optInInvestment: any) => {
                        return (
                            optInInvestment.requested_amount ===
                            vm.investment.investment_amount
                        );
                    }
                );
                const allConfirmed = FundOptInInvestment.allConfirmed(vm.optInInvestments);
                const allCompleted = completedOptIns.length === vm.optInInvestments.length;

                if (allConfirmed && allCompleted) {
                    return vm.COMPLETE;
                } else if (allConfirmed) {
                    return vm.PENDING;
                } else {
                    return vm.INCOMPLETE;
                }
            },
            'individualIdentityVerification': () => {
                if (vm.individualInvestorProfile.identity_verified) {
                    return vm.COMPLETE;
                }

                if (
                    vm.individualIdentityDocuments.length &&
                    vm.userProfile.citizenship_country &&
                    vm.userProfile.dob
                ) {
                    return vm.PENDING;
                }

                return vm.INCOMPLETE;
            },
            'iraAccountStatus': () => {
                if (
                    vm.investorProfile.ira_account_status === FCChoices.EntrustIRAAccountStatusChoices.Approved ||
                    vm.investorProfile.ira_account_status === FCChoices.EntrustIRAAccountStatusChoices.LegacyAccount
                ) {
                    return vm.COMPLETE;
                }

                if (vm.investorProfile.ira_account_status === FCChoices.EntrustIRAAccountStatusChoices.Pending) {
                    return vm.PENDING;
                }

                return vm.INCOMPLETE;
            },
            'payment': () => {
                // With an IRA account, there is nothing you can ever do for the actual
                // payment step.  There is a separate 'Fund IRA' step where you mark
                // your payment as sent.
                if (vm.investorProfile.is_ira) {
                    return vm.PENDING;
                }

                if (
                    vm.payment.user_marked_offline_payment_sent !==
                    FCChoices.OfflinePaymentSentChoices.NotSent
                ) {
                    return vm.PENDING;
                } else {
                    return vm.INCOMPLETE;
                }
            },
            'selectRefundAccount': () => {
                if (!vm.askForRefundAccount()) {
                    return vm.COMPLETE;
                }
                if (
                    vm.investment.refund_wire_account
                ) {
                    return vm.COMPLETE;
                } else {
                    return vm.INCOMPLETE;
                }
            },
            'signSubscriptionAgreement': () => {
                if (vm.subscriptionAgreement.status === FCChoices.SigningStatus.NotInitialized) {
                    // not initialized
                    return vm.PENDING;
                } else if (vm.subscriptionAgreement.status === FCChoices.SigningStatus.RequiresCountersignature ||
                           vm.subscriptionAgreement.status === FCChoices.SigningStatus.Signed) {
                    // signed
                    return vm.COMPLETE;
                }
                return vm.INCOMPLETE;
            },
            'testHellosign': () => {
                if (vm.hellosignState === 'generating') {
                    return vm.PENDING;
                }
                return vm.INCOMPLETE;
            },
            'signTaxDocs': () => {
                if (vm.taxDocs.status === FCChoices.SigningStatus.NotInitialized) {
                    // not initialized
                    return vm.PENDING;
                } else if (vm.taxDocs.status === FCChoices.SigningStatus.RequiresCountersignature ||
                           vm.taxDocs.status === FCChoices.SigningStatus.Signed) {
                    // signed
                    return vm.COMPLETE;
                }
                return vm.INCOMPLETE;
            },
            'transaction': (transaction: any) => {
                if (transaction.their_bank_account && !transaction.their_bank_account.verified) {
                    return vm.INCOMPLETE;
                }

                if (transaction.is_failed) {
                    return vm.INCOMPLETE;
                }

                if (transaction.is_awaiting_user_confirmation) {
                    return vm.PENDING;
                }

                return vm.COMPLETE;
            },
            'verifyBankAccount': (transaction: any) => {
                if (!transaction.their_bank_account.verified) {
                    return vm.INCOMPLETE;
                }

                return vm.COMPLETE;
            },
            'connectLinkedin': () => {
                if (['unknown', 'success'].indexOf(vm.userProfile.linkedin_status) !== -1) {
                    return vm.COMPLETE;
                }

                return vm.INCOMPLETE;
            },
        };

        const isActionRequired: any = {
            'emailVerification': () => {
                const status = vm.stepStatus.emailVerification();
                return [vm.PENDING, vm.INCOMPLETE].indexOf(status) !== -1;
            },
            'entityAccreditation': () => {
                return vm.stepStatus.entityAccreditation() === vm.INCOMPLETE;
            },
            'entityIdentityVerification': () => {
                return vm.stepStatus.entityIdentityVerification() === vm.INCOMPLETE;
            },
            'fundIra': () => {
                return vm.stepStatus.fundIra() === vm.INCOMPLETE;
            },
            'fundOptIns': () => {
                return vm.stepStatus.fundOptIns() === vm.INCOMPLETE;
            },
            'individualIdentityVerification': () => {
                return vm.stepStatus.individualIdentityVerification() === vm.INCOMPLETE;
            },
            'iraAccountStatus': () => {
                return vm.stepStatus.iraAccountStatus() === vm.INCOMPLETE;
            },
            'payment': () => {
                // If we are an IRA, this step's status matches that of fund-ira
                if (vm.investorProfile.is_ira) {
                    return vm.stepStatus.fundIra() === vm.INCOMPLETE;
                } else {
                    return vm.stepStatus.payment() === vm.INCOMPLETE;
                }
            },
            'selectRefundAccount': () => {
                return vm.stepStatus.selectRefundAccount() === vm.INCOMPLETE;
            },
            'signSubscriptionAgreement': () => {
                const status = vm.stepStatus.signSubscriptionAgreement();
                return [vm.PENDING, vm.INCOMPLETE].indexOf(status) !== -1;
            },
            'testHellosign': () => {
                return true;
            },
            'signTaxDocs': () => {
                const status = vm.stepStatus.signTaxDocs();
                return [vm.PENDING, vm.INCOMPLETE].indexOf(status) !== -1;
            },
            'transaction': (transaction: any) => {
                const status = vm.stepStatus.transaction(transaction);
                return [vm.PENDING, vm.INCOMPLETE].indexOf(status) !== -1;
            },
            'verifyBankAccount': (transaction: any) => {
                return vm.stepStatus.verifyBankAccount(transaction) === vm.INCOMPLETE;
            },
            'connectLinkedin': () => {
                return ['', 'token-expired'].indexOf(vm.userProfile.linkedin_status) !== -1;
            },
        };

        vm.actionRequiredSteps = relevantSteps().filter((step: any) => {
            if (step instanceof Array) {
                let [stepName, obj] = step;
                return isActionRequired[stepName](obj);
            } else {
                return isActionRequired[step]();
            }
        });
        vm.investmentIncomplete = vm.actionRequiredSteps.length > 0;
        vm.investmentComplete = !vm.investmentIncomplete;

        vm.resetOnlinePayment = () => {
            vm.resettingOnlinePayment = true;
            vm.payment
                .resetOnlinePayment()
                .then(() => {
                    $window.location.reload();
                });
        };

        vm.connectLinkedin = () => {
            vm.connectingLinkedin = true;
            let newWindow = $window.open(
                vm.userProfile.urls.async_connect_linkedin,
                'name',
                'height=600,width=600'
            );
            $window.linkedinConnectedCallback = (errors: any) => {
                $timeout(() => {
                    console.log(errors); // TODO
                    vm.userProfile.linkedin_status = 'unknown';
                    vm.connectingLinkedin = false;
                    newWindow.close();
                });
            };
        };

        vm.linkedinAlreadyConnected = vm.stepComplete('connectLinkedin');

        if (vm.scrollTo !== null) {
            $timeout(() => {
                $window.scrollTo(0, vm.scrollTo);
                vm.shouldAutoScroll = true;
            });
        }
    };
    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'asc',
        restrict: 'E',
        scope: thisScope,
        templateUrl: 'icp/icp-home.directive.html',
    };
    return thisDirective;
};

angular
    .module('fundersclub.icp')
    .directive('fcIcpHome', fcIcpHome);
