let fcPayment = () => {
    let thisScope = {
        'fund': '=fund',
        'investment': '=investment',
        'investorProfile': '=investorProfile',
        'payment': '=payment',
    };
    let thisController = function(
        $scope: any,
        $state: any,
        $stateParams: any,
        FCChoices: any,
        filterFilter: any,
        Payment: any
    ) {
        let vm = this;
        vm.choices = FCChoices;
        vm.hasEmailedInstructions = false;
        vm.isEmailingInstructions = false;

        // Payment is in a state that means a user should not be here
        if (
            vm.payment.is_settled ||
            vm.payment.transaction_status === 'f' ||
            vm.payment.unaccounted_amount === 0
        ) {
            $state.go('icp');
            return;
        }

        vm.bankAccount = null;
        vm.bankAccountId = null;

        if ($stateParams.paymentType) {
            vm.paymentType = $stateParams.paymentType;
        } else {
            vm.paymentType = vm.payment.user_marked_offline_payment_sent;
        }
        vm.markingSent = false;

        vm.canPayWithWire = function() {
            return !(
                vm.fund.online_payments_only ||
                vm.fund.payment_by_wire_disabled ||
                (
                    vm.payment.user_marked_offline_payment_sent ===
                    FCChoices.OfflinePaymentSentChoices.Check
                )
            );
        };

        vm.canPayWithCheck = function() {
            return !(
                vm.fund.online_payments_only ||
                vm.fund.payment_by_check_disabled ||
                (
                    vm.payment.user_marked_offline_payment_sent ===
                    FCChoices.OfflinePaymentSentChoices.Wire
                ) ||
                vm.investorProfile.is_international
            );
        };

        vm.canPayOnline = function() {
            return vm.fund.online_payments && !(
                [
                    FCChoices.OfflinePaymentSentChoices.Check,
                    FCChoices.OfflinePaymentSentChoices.Wire,
                ].indexOf(vm.payment.user_marked_offline_payment_sent) !== -1
            );
        };

        vm.payNowEnabled = function() {
            return (
                !vm.saving && // Not currently saving
                vm.bankAccountId && // Has bank account selected
                vm.bankAccountId !== -1 && // That isn't the "new account form"
                vm.bankAccount && // A selected bank account exists
                vm.bankAccount.verified // Selected bank account is verified
            );
        };

        vm.chargeBankAccount = function() {
            vm.saving = true;
            vm.payment
                .switchToOnlinePayment(vm.bankAccountId)
                .then(function(response: any) {
                    angular.extend(vm.payment, response.data);
                    $state.go('icp');
                })
                .finally(function() {
                    vm.saving = false;
                });
        };

        vm.markPaymentSent = function(paymentType: any) {
            vm.markingSent = true;
            vm.payment.user_marked_offline_payment_sent = paymentType;
            vm.payment
                .$save(['user_marked_offline_payment_sent'])
                .then(function() {
                    vm.markingSent = false;
                    $state.go('icp');
                });
        };

        vm.payOnline = function() {
            return vm.paymentType === 'online';
        };

        vm.payOnlineDisabledTooltipText = function() {
            let reasons = new Array();

            if (!vm.fund.online_payments) {
                reasons.push('Payments via ACH are no longer available for this fund.');
            }

            if ([
                    FCChoices.OfflinePaymentSentChoices.Check,
                    FCChoices.OfflinePaymentSentChoices.Wire,
                ].indexOf(vm.payment.user_marked_offline_payment_sent) !== -1
            ) {
                const method = vm.payment.user_marked_offline_payment_sent;
                reasons.push(
                    `You have marked that you have sent in a ${method} as payment.
                    If this is incorrect, please correct this and then you may pay using
                    a bank account.`
                );
            }

            return `<span class="u-tS-sm">${reasons.join('<br><br>')}</span>`;
        };

        vm.payWithCheckDisabledTooltipText = function() {
            let reasons = new Array();

            if (vm.investorProfile.is_international) {
                reasons.push('Paying via check is only supported for those in the US');
            }

            if (vm.fund.online_payments_only) {
                reasons.push('Payment via check are no longer available for this fund.');
            }

            if (
                vm.payment.user_marked_offline_payment_sent ===
                FCChoices.OfflinePaymentSentChoices.Wire
            ) {
                reasons.push(
                    `You have marked that you have sent in a wire as payment.
                    If this is incorrect, please correct this and then you may pay using
                    a check.`
                );
            }

            if (vm.fund.payment_by_check_disabled) {
                reasons.push('Payment via check is no longer available for this fund.');
            }

            return `<span class="u-tS-sm">${reasons.join('<br><br>')}</span>`;
        };

        vm.payWithCheck = function() {
            return vm.paymentType === 'check';
        };

        vm.payWithWire = function() {
            return vm.paymentType === 'wire';
        };

        vm.payWithWireDisabledTooltipText = function() {
            let reasons = new Array();

            if (vm.fund.online_payments_only) {
                reasons.push('Payment via wire is no longer available for this fund.');
            }

            if (
                vm.payment.user_marked_offline_payment_sent ===
                FCChoices.OfflinePaymentSentChoices.Check
            ) {
                reasons.push(
                    `You have marked that you have sent in a check as payment.
                    If this is incorrect, please correct this and then you may pay using
                    a wire.`
                );
            }

            if (vm.fund.payment_by_wire_disabled) {
                reasons.push('Payment via wire is not available for this fund.');
            }

            return `<span class="u-tS-sm">${reasons.join('<br><br>')}</span>`;
        };

        vm.switchPaymentType = (paymentType: any) => {
            vm.paymentType = paymentType;
            $state.go('icp.payment', {'paymentType': paymentType});
        };

        vm.unmarkPaymentSent = function() {
            vm.unmarkingSent = true;
            vm.payment.user_marked_offline_payment_sent = (
                FCChoices.OfflinePaymentSentChoices.NotSent
            );
            vm.payment
                .$save(['user_marked_offline_payment_sent'])
                .then(function() {
                    vm.unmarkingSent = false;
                });
        };

        vm.sendWireInstructions = () => {
            vm.isEmailingInstructions = true;
            vm.investment.sendWiringInstructions().then(() => {
                vm.hasEmailedInstructions = true;
            });
        };
    };
    let thisDirective = {
        bindToController: true,
        controller: thisController,
        controllerAs: 'pc',
        restrict: 'E',
        scope: thisScope,
        templateUrl: 'icp/payment.directive.html',
    };
    return thisDirective;
};

angular
    .module('fundersclub.icp')
    .directive('fcPayment', fcPayment);
